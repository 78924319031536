import apiClient from './axios';

/* =========================
   ATTRIBUTES ENDPOINTS
   ========================= */

export const getAttributes = async (filters = {}) => {
  try {
    const response = await apiClient.get('/attributes', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching attributes:', error);
    throw error;
  }
};

export const getAttribute = async (attributeId, lang) => {
  try {
    const response = await apiClient.get(`/attributes/${attributeId}`, {
      params: lang ? { lang } : {},
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching attribute with id ${attributeId}:`, error);
    throw error;
  }
};

export const createAttribute = async (data) => {
  try {
    const response = await apiClient.post('/attributes', data);
    return response.data;
  } catch (error) {
    console.error('Error creating attribute:', error);
    throw error;
  }
};

export const updateAttribute = async (attributeId, data) => {
  try {
    const response = await apiClient.put(`/attributes/${attributeId}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating attribute with id ${attributeId}:`, error);
    throw error;
  }
};

export const deleteAttribute = async (attributeId) => {
  try {
    const response = await apiClient.delete(`/attributes/${attributeId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting attribute with id ${attributeId}:`, error);
    throw error;
  }
};

/* =========================
   ATTRIBUTE VALUES ENDPOINTS
   ========================= */

export const getAttributeValues = async (filters = {}) => {
  try {
    const response = await apiClient.get('/attribute_values', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching attribute values:', error);
    throw error;
  }
};

export const getAttributeValue = async (valueId) => {
  try {
    const response = await apiClient.get(`/attribute_values/${valueId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching attribute value with id ${valueId}:`, error);
    throw error;
  }
};

export const createAttributeValue = async (data) => {
  try {
    const response = await apiClient.post('/attribute_values', data);
    return response.data;
  } catch (error) {
    console.error('Error creating attribute value:', error);
    throw error;
  }
};

export const updateAttributeValue = async (valueId, data) => {
  try {
    const response = await apiClient.put(`/attribute_values/${valueId}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating attribute value with id ${valueId}:`, error);
    throw error;
  }
};

export const deleteAttributeValue = async (valueId) => {
  try {
    const response = await apiClient.delete(`/attribute_values/${valueId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting attribute value with id ${valueId}:`, error);
    throw error;
  }
};

/* =========================
   PRODUCT ATTRIBUTES ENDPOINTS
   ========================= */

export const getProductAttributes = async (productId) => {
  try {
    const response = await apiClient.get(`/products/${productId}/attributes`);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching attributes for product id ${productId}:`,
      error,
    );
    throw error;
  }
};

export const addProductAttribute = async (productId, attributeId) => {
  try {
    const response = await apiClient.post(`/products/${productId}/attributes`, {
      attribute_id: attributeId,
    });
    return response.data;
  } catch (error) {
    console.error(
      `Error adding attribute ${attributeId} to product ${productId}:`,
      error,
    );
    throw error;
  }
};

export const removeProductAttribute = async (productId, attributeId) => {
  try {
    const response = await apiClient.delete(
      `/products/${productId}/attributes/${attributeId}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error removing attribute ${attributeId} from product ${productId}:`,
      error,
    );
    throw error;
  }
};

/* =========================
   CATEGORY ATTRIBUTES ENDPOINTS
   ========================= */

export const getCategoryAttributes = async (categoryId) => {
  try {
    const response = await apiClient.get(
      `/categories/${categoryId}/attributes`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching attributes for category id ${categoryId}:`,
      error,
    );
    throw error;
  }
};

export const addCategoryAttribute = async (categoryId, attributeId) => {
  try {
    const response = await apiClient.post(
      `/categories/${categoryId}/attributes`,
      {
        attribute_id: attributeId,
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error adding attribute ${attributeId} to category ${categoryId}:`,
      error,
    );
    throw error;
  }
};

export const removeCategoryAttribute = async (categoryId, attributeId) => {
  try {
    const response = await apiClient.delete(
      `/categories/${categoryId}/attributes/${attributeId}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error removing attribute ${attributeId} from category ${categoryId}:`,
      error,
    );
    throw error;
  }
};

/* =========================
   PRODUCT VARIATIONS ENDPOINTS
   ========================= */

// Get all product variations (optionally filtered by product_id and is_active)
export const getProductVariations = async (filters = {}) => {
  try {
    const response = await apiClient.get('/product-variations', {
      params: filters,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching product variations:', error);
    throw error;
  }
};

// Get a single product variation by ID
export const getProductVariation = async (variationId) => {
  try {
    const response = await apiClient.get(`/product-variations/${variationId}`);
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching product variation with id ${variationId}:`,
      error,
    );
    throw error;
  }
};

// Create a new product variation
export const createProductVariation = async (data) => {
  try {
    const response = await apiClient.post('/product-variations', data);
    return response.data;
  } catch (error) {
    console.error('Error creating product variation:', error);
    throw error;
  }
};

// Update an existing product variation by ID
export const updateProductVariation = async (variationId, data) => {
  try {
    const response = await apiClient.put(
      `/product-variations/${variationId}`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error updating product variation with id ${variationId}:`,
      error,
    );
    throw error;
  }
};

// Delete a product variation by ID
export const deleteProductVariation = async (variationId) => {
  try {
    const response = await apiClient.delete(
      `/product-variations/${variationId}`,
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error deleting product variation with id ${variationId}:`,
      error,
    );
    throw error;
  }
};
