import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  Select,
  Input,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
} from '@chakra-ui/react';

const OrdersFilter = ({
  isOpen,
  onClose,
  orderType,
  setOrderType,
  status,
  setStatus,
  dateRange,
  setDateRange,
  onClear,
  onApply,
}) => {
  const bgColor = useColorModeValue('white', 'gray.800');

  const handleStartDateChange = (e) => {
    setDateRange((prev) => ({ ...prev, start: e.target.value }));
  };

  const handleEndDateChange = (e) => {
    setDateRange((prev) => ({ ...prev, end: e.target.value }));
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent bg={bgColor}>
        <DrawerHeader borderBottomWidth="1px">Filter Orders</DrawerHeader>
        <DrawerBody>
          <FormControl mb={4}>
            <FormLabel>Order Type</FormLabel>
            <Select
              value={orderType}
              onChange={(e) => setOrderType(e.target.value)}
            >
              <option value="all">All Orders</option>
              <option value="products">Products</option>
              <option value="loyalty">Loyalty Programs</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Status</FormLabel>
            <Select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="all">All Statuses</option>
              <option value="initiated">Initiated</option>
              <option value="processing">Processing</option>
              <option value="paid">Paid</option>
              <option value="complete">Complete</option>
              <option value="failed">Failed</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Date Range</FormLabel>
            <Flex gap={2}>
              <Input
                type="date"
                value={dateRange.start}
                onChange={handleStartDateChange}
              />
              <Input
                type="date"
                value={dateRange.end}
                onChange={handleEndDateChange}
              />
            </Flex>
          </FormControl>
          <Flex justify="space-between" mt={2}>
            <Button colorScheme="red" onClick={onClear}>
              Clear Filters
            </Button>
            <Button colorScheme="blue" onClick={onApply}>
              Apply Filters
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default OrdersFilter;
