import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import AttributesPage from '../../pages/variations/AttributesPage';

function ManageAttributesModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button colorScheme="teal" onClick={onOpen}>
        Manage Attributes & Values
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        motionPreset="slideInBottom"
        isCentered
        scrollBehavior="inside"
        aria-labelledby="modal-attributes-title"
        aria-describedby="modal-attributes-description"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Attributes & Values</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AttributesPage />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ManageAttributesModal;
