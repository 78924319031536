import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Icon,
  Checkbox,
  Text,
  VStack,
  Collapse,
  useColorModeValue,
  Input,
  Divider,
  Button,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import {
  ChevronRightIcon,
  ChevronDownIcon,
  SearchIcon,
} from '@chakra-ui/icons';
import PropTypes from 'prop-types';

const CategoryFilter = ({
  categories,
  selectedCategoryUuids,
  onFilterChange,
  featured,
  setFeatured,
  showArchived,
  setShowArchived,
}) => {
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const selectedRef = useRef(null);

  const findParentNodes = (categoriesList, targetValue, parents = []) => {
    for (const category of categoriesList) {
      if (category.value === targetValue) {
        return parents;
      }
      if (category.children && category.children.length > 0) {
        const result = findParentNodes(category.children, targetValue, [
          ...parents,
          category.value,
        ]);
        if (result) return result;
      }
    }
    return null;
  };

  const getAllExpandableCategoryValues = (categoriesList) => {
    let values = [];
    categoriesList.forEach((category) => {
      if (category.children && category.children.length > 0) {
        values.push(category.value);
        values = values.concat(
          getAllExpandableCategoryValues(category.children),
        );
      }
    });
    return values;
  };

  const handleExpandAll = () => {
    const allExpandable = getAllExpandableCategoryValues(categories);
    setExpandedNodes(allExpandable);
    setIsAllExpanded(true);
  };

  const handleCollapseAll = () => {
    setExpandedNodes([]);
    setIsAllExpanded(false);
  };

  useEffect(() => {
    if (selectedCategoryUuids && selectedCategoryUuids.length > 0) {
      let allParents = [];
      selectedCategoryUuids.forEach((uuid) => {
        const parents = findParentNodes(categories, uuid);
        if (parents) {
          allParents = [...allParents, ...parents];
        }
      });
      setExpandedNodes((prev) => [...new Set([...prev, ...allParents])]);
    }
  }, [categories, selectedCategoryUuids]);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedCategoryUuids, expandedNodes]);

  const toggleExpand = (value) => {
    if (expandedNodes.includes(value)) {
      setExpandedNodes((prev) => prev.filter((v) => v !== value));
    } else {
      setExpandedNodes((prev) => [...prev, value]);
    }
  };

  const handleSelectCategory = (value) => {
    if (value === '') {
      onFilterChange([]);
    } else {
      if (selectedCategoryUuids.includes(value)) {
        onFilterChange(selectedCategoryUuids.filter((v) => v !== value));
      } else {
        onFilterChange([...selectedCategoryUuids, value]);
      }
    }
  };

  const filterCategories = (categoriesList, query) => {
    const filtered = [];
    categoriesList.forEach((category) => {
      if (category.label.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(category);
      } else if (category.children && category.children.length > 0) {
        const filteredChildren = filterCategories(category.children, query);
        if (filteredChildren.length > 0) {
          filtered.push({ ...category, children: filteredChildren });
        }
      }
    });
    return filtered;
  };

  const filteredCategories = useMemo(() => {
    if (searchQuery.trim() === '') {
      return categories;
    }
    return filterCategories(categories, searchQuery.trim());
  }, [categories, searchQuery]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      if (selectedCategoryUuids && selectedCategoryUuids.length > 0) {
        let allParents = [];
        selectedCategoryUuids.forEach((uuid) => {
          const parents = findParentNodes(categories, uuid);
          if (parents) {
            allParents = [...allParents, ...parents];
          }
        });
        setExpandedNodes((prev) => [...new Set([...prev, ...allParents])]);
      }
    } else {
      const allExpandable = getAllExpandableCategoryValues(filteredCategories);
      setExpandedNodes(allExpandable);
    }
  }, [searchQuery, categories, filteredCategories, selectedCategoryUuids]);

  const renderCategories = (categoriesList, depth = 0) => {
    return categoriesList.map((category) => {
      const isExpanded = expandedNodes.includes(category.value);
      const hasChildren = category.children && category.children.length > 0;
      const isSelected = selectedCategoryUuids.includes(category.value);

      const isMatch =
        searchQuery.length > 0 &&
        category.label.toLowerCase().includes(searchQuery.toLowerCase());

      return (
        <Box key={category.value} pl={4 * depth} py={1}>
          <Flex align="center" justify="space-between">
            <Flex align="center">
              {hasChildren && (
                <Icon
                  as={isExpanded ? ChevronDownIcon : ChevronRightIcon}
                  mr={2}
                  cursor="pointer"
                  onClick={() => toggleExpand(category.value)}
                />
              )}
              {!hasChildren && <Box width="24px" mr={2} />}
              <Checkbox
                isChecked={isSelected}
                onChange={() => handleSelectCategory(category.value)}
                mr={2}
                pointerEvents="auto"
                ref={isSelected ? selectedRef : null}
              />
              <Text
                fontWeight={isSelected ? 'bold' : 'normal'}
                color={isSelected ? 'blue.600' : 'inherit'}
                onClick={() => {
                  if (hasChildren) {
                    toggleExpand(category.value);
                  } else {
                    handleSelectCategory(category.value);
                  }
                }}
                cursor={hasChildren ? 'pointer' : 'default'}
              >
                {isMatch ? (
                  <Box as="span" bg="yellow.200">
                    {category.label}
                  </Box>
                ) : (
                  category.label
                )}
              </Text>
            </Flex>
          </Flex>
          {hasChildren && (
            <Collapse in={isExpanded} animateOpacity>
              {renderCategories(category.children, depth + 1)}
            </Collapse>
          )}
        </Box>
      );
    });
  };

  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      p={4}
      maxHeight="600px" // Increased height to accommodate additional filters
      overflowY="auto"
      bg={useColorModeValue('white', 'gray.800')}
    >
      {/* Search Input */}
      <Flex mb={4} align="center">
        <Input
          placeholder="Search categories..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="sm"
          mr={2}
        />
        <Icon as={SearchIcon} color="gray.500" />
      </Flex>

      {/* Expand/Collapse & Clear Categories Buttons */}
      <Flex justify="space-between" align="center" mb={4}>
        <Flex align="center">
          <Checkbox
            isChecked={selectedCategoryUuids.length === 0}
            onChange={() => handleSelectCategory('')}
            mr={2}
          />
          <Text
            fontWeight={selectedCategoryUuids.length === 0 ? 'bold' : 'normal'}
            color={selectedCategoryUuids.length === 0 ? 'blue.600' : 'inherit'}
            onClick={() => handleSelectCategory('')}
            cursor="pointer"
          >
            All Categories
          </Text>
        </Flex>
        <Flex>
          <Button
            size="xs"
            variant="outline"
            mr={2}
            onClick={() => onFilterChange([])}
          >
            Clear Categories
          </Button>
          <Button
            size="xs"
            variant="outline"
            onClick={isAllExpanded ? handleCollapseAll : handleExpandAll}
            leftIcon={
              isAllExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />
            }
          >
            {isAllExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
        </Flex>
      </Flex>

      {/* Render the category tree */}
      <VStack align="start" spacing={0}>
        {renderCategories(filteredCategories)}
      </VStack>

      {/* Divider before additional filters */}
      <Divider my={4} />

      {/* Additional Filters: Featured and Show Archived */}
      <VStack align="start" spacing={4}>
        {/* Featured Switch */}
        <FormControl display="flex" alignItems="center">
          <Switch
            id="featured"
            colorScheme="blue"
            isChecked={featured}
            onChange={(e) => {
              setFeatured(e.target.checked);
            }}
            mr={2}
          />
          <FormLabel htmlFor="featured" mb="0" cursor="pointer">
            Featured
          </FormLabel>
        </FormControl>
        {/* Show Archived Switch */}
        <FormControl display="flex" alignItems="center">
          <Switch
            id="showArchived"
            colorScheme="blue"
            isChecked={showArchived}
            onChange={(e) => {
              setShowArchived(e.target.checked);
            }}
            mr={2}
          />
          <FormLabel htmlFor="showArchived" mb="0" cursor="pointer">
            Show Archived
          </FormLabel>
        </FormControl>
      </VStack>
    </Box>
  );
};

CategoryFilter.propTypes = {
  categories: PropTypes.array.isRequired,
  // Updated: selectedCategoryUuids is now expected to be an array
  selectedCategoryUuids: PropTypes.array,
  onFilterChange: PropTypes.func.isRequired,
  featured: PropTypes.bool.isRequired,
  setFeatured: PropTypes.func.isRequired,
  showArchived: PropTypes.bool.isRequired,
  setShowArchived: PropTypes.func.isRequired,
};

export default CategoryFilter;
