import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  Select,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  getAttributes,
  createAttribute,
  updateAttribute,
  deleteAttribute,
  getAttributeValues,
  createAttributeValue,
  updateAttributeValue,
  deleteAttributeValue,
} from '../../api/attributesVariations';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'et', name: 'Estonian' },
];

const AttributesPage = () => {
  const toast = useToast();

  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [formData, setFormData] = useState({
    slug: '',
    type: 'product',
    translations: {},
  });
  const {
    isOpen: isAttrModalOpen,
    onOpen: onAttrModalOpen,
    onClose: onAttrModalClose,
  } = useDisclosure();

  const [valuesModalOpen, setValuesModalOpen] = useState(false);
  const [selectedAttributeForValues, setSelectedAttributeForValues] =
    useState(null);
  const [attributeValues, setAttributeValues] = useState([]);
  const [loadingValues, setLoadingValues] = useState(false);
  const [valueFormData, setValueFormData] = useState({
    slug: '',
    translations: {},
  });
  const [editingValue, setEditingValue] = useState(null);

  const {
    isOpen: isAttrDeleteConfirmOpen,
    onOpen: onAttrDeleteConfirmOpen,
    onClose: onAttrDeleteConfirmClose,
  } = useDisclosure();
  const [attributeToDelete, setAttributeToDelete] = useState(null);
  const attrCancelRef = React.useRef();

  const {
    isOpen: isValueDeleteConfirmOpen,
    onOpen: onValueDeleteConfirmOpen,
    onClose: onValueDeleteConfirmClose,
  } = useDisclosure();
  const [valueToDelete, setValueToDelete] = useState(null);
  const valueCancelRef = React.useRef();

  const fetchAttributes = async () => {
    setLoading(true);
    try {
      const data = await getAttributes();
      setAttributes(data);
    } catch (error) {
      toast({
        title: 'Error fetching attributes',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAttributeValues = async (attributeId) => {
    setLoadingValues(true);
    try {
      const data = await getAttributeValues({ attribute_id: attributeId });
      setAttributeValues(data);
    } catch (error) {
      toast({
        title: 'Error fetching attribute values',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoadingValues(false);
    }
  };

  useEffect(() => {
    fetchAttributes();
  }, []);

  const handleOpenAttrModal = (attribute = null) => {
    setSelectedAttribute(attribute);
    if (attribute) {
      const translationsObj = {};
      languages.forEach((lang) => {
        translationsObj[lang.code] =
          attribute.translations?.find((tr) => tr.language === lang.code)
            ?.value || '';
      });
      setFormData({
        slug: attribute.slug,
        type: attribute.type,
        translations: translationsObj,
      });
    } else {
      const translationsObj = {};
      languages.forEach((lang) => {
        translationsObj[lang.code] = '';
      });
      setFormData({ slug: '', type: 'product', translations: translationsObj });
    }
    onAttrModalOpen();
  };

  const handleDeleteAttributeConfirm = (attribute) => {
    setAttributeToDelete(attribute);
    onAttrDeleteConfirmOpen();
  };

  const confirmDeleteAttribute = async () => {
    try {
      await deleteAttribute(attributeToDelete.id);
      toast({
        title: 'Attribute deleted',
        status: 'success',
        isClosable: true,
      });
      fetchAttributes();
    } catch (error) {
      toast({
        title: 'Error deleting attribute',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onAttrDeleteConfirmClose();
      setAttributeToDelete(null);
    }
  };

  const handleAttrSubmit = async () => {
    const translationsArray = Object.keys(formData.translations).map(
      (langCode) => ({
        language: langCode,
        key: 'name',
        value: formData.translations[langCode],
      }),
    );

    const payload = {
      slug: formData.slug,
      type: formData.type,
      translations: translationsArray,
    };

    try {
      if (selectedAttribute) {
        await updateAttribute(selectedAttribute.id, payload);
        toast({
          title: 'Attribute updated',
          status: 'success',
          isClosable: true,
        });
      } else {
        await createAttribute(payload);
        toast({
          title: 'Attribute created',
          status: 'success',
          isClosable: true,
        });
      }
      fetchAttributes();
      onAttrModalClose();
      setSelectedAttribute(null);
      setFormData({ slug: '', type: 'product', translations: {} });
    } catch (error) {
      toast({
        title: 'Error saving attribute',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const openValuesModal = (attribute) => {
    setSelectedAttributeForValues(attribute);
    const translationsObj = {};
    languages.forEach((lang) => {
      translationsObj[lang.code] = '';
    });
    setValueFormData({ slug: '', translations: translationsObj });
    setEditingValue(null);
    setValuesModalOpen(true);
    fetchAttributeValues(attribute.id);
  };

  const closeValuesModal = () => {
    setValuesModalOpen(false);
    setSelectedAttributeForValues(null);
    setAttributeValues([]);
  };

  const handleValueSubmit = async () => {
    const translationsArray = Object.keys(valueFormData.translations).map(
      (langCode) => ({
        language: langCode,
        key: 'name',
        value: valueFormData.translations[langCode],
      }),
    );

    const payload = {
      attribute_id: selectedAttributeForValues.id,
      slug: valueFormData.slug,
      translations: translationsArray,
    };

    try {
      if (editingValue) {
        await updateAttributeValue(editingValue.id, payload);
        toast({
          title: 'Attribute value updated',
          status: 'success',
          isClosable: true,
        });
      } else {
        await createAttributeValue(payload);
        toast({
          title: 'Attribute value created',
          status: 'success',
          isClosable: true,
        });
      }
      fetchAttributeValues(selectedAttributeForValues.id);
      const translationsObj = {};
      languages.forEach((lang) => {
        translationsObj[lang.code] = '';
      });
      setValueFormData({ slug: '', translations: translationsObj });
      setEditingValue(null);
    } catch (error) {
      toast({
        title: 'Error saving attribute value',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleEditValue = (value) => {
    setEditingValue(value);
    const translationsObj = {};
    languages.forEach((lang) => {
      translationsObj[lang.code] =
        value.translations?.find((tr) => tr.language === lang.code)?.value ||
        '';
    });
    setValueFormData({ slug: value.slug, translations: translationsObj });
  };

  const handleDeleteValueConfirm = (value) => {
    setValueToDelete(value);
    onValueDeleteConfirmOpen();
  };

  const confirmDeleteValue = async () => {
    try {
      await deleteAttributeValue(valueToDelete.id);
      toast({
        title: 'Attribute value deleted',
        status: 'success',
        isClosable: true,
      });
      fetchAttributeValues(selectedAttributeForValues.id);
    } catch (error) {
      toast({
        title: 'Error deleting attribute value',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onValueDeleteConfirmClose();
      setValueToDelete(null);
    }
  };

  return (
    <Box p={5}>
      <Heading mb={4}>Attributes</Heading>
      <Button colorScheme="blue" onClick={() => handleOpenAttrModal()} mb={4}>
        Add Attribute
      </Button>
      {loading ? (
        <Spinner />
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Name</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {attributes.map((attribute) => {
              // Show the English translation as the default name.
              const englishName =
                attribute.translations?.find((tr) => tr.language === 'en')
                  ?.value || '';
              return (
                <Tr key={attribute.id}>
                  <Td>{attribute.id}</Td>
                  <Td>{englishName}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="teal"
                      mr={2}
                      onClick={() => handleOpenAttrModal(attribute)}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="red"
                      mr={2}
                      onClick={() => handleDeleteAttributeConfirm(attribute)}
                    >
                      <DeleteIcon />
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="purple"
                      onClick={() => openValuesModal(attribute)}
                    >
                      Manage Values
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}

      {/* Modal for Creating/Editing an Attribute */}
      <Modal
        isOpen={isAttrModalOpen}
        onClose={() => {
          onAttrModalClose();
          setSelectedAttribute(null);
          setFormData({ slug: '', type: 'product', translations: {} });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedAttribute ? 'Edit Attribute' : 'Add Attribute'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb="4">
              <FormLabel>Slug</FormLabel>
              <Input
                placeholder="Enter slug"
                value={formData.slug}
                onChange={(e) =>
                  setFormData({ ...formData, slug: e.target.value })
                }
              />
            </FormControl>
            <FormControl mb="4">
              <FormLabel>Type</FormLabel>
              <Input value="product" isDisabled />
            </FormControl>
            <Tabs variant="enclosed" colorScheme="blue" mb="20px">
              <TabList>
                {languages.map((lang) => (
                  <Tab key={lang.code}>{lang.name}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {languages.map((lang) => (
                  <TabPanel key={lang.code}>
                    <FormControl>
                      <FormLabel>Name ({lang.name})</FormLabel>
                      <Input
                        placeholder={`Enter attribute name in ${lang.name}`}
                        value={formData.translations[lang.code] || ''}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            translations: {
                              ...formData.translations,
                              [lang.code]: e.target.value,
                            },
                          })
                        }
                      />
                    </FormControl>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onAttrModalClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleAttrSubmit}>
              {selectedAttribute ? 'Update' : 'Create'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Managing Attribute Values */}
      <Modal isOpen={valuesModalOpen} onClose={closeValuesModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Manage Values for Attribute:{' '}
            {selectedAttributeForValues &&
              (selectedAttributeForValues.translations.find(
                (tr) => tr.language === 'en',
              )?.value ||
                selectedAttributeForValues.slug)}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Button to clear the value form for adding a new value */}
            <Button
              colorScheme="blue"
              mb="4"
              onClick={() => {
                const translationsObj = {};
                languages.forEach((lang) => {
                  translationsObj[lang.code] = '';
                });
                setValueFormData({ slug: '', translations: translationsObj });
                setEditingValue(null);
              }}
            >
              Add Value
            </Button>
            {loadingValues ? (
              <Spinner />
            ) : (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>ID</Th>
                    <Th>Name</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {attributeValues.map((val) => {
                    const englishName =
                      val.translations?.find((tr) => tr.language === 'en')
                        ?.value || '';
                    return (
                      <Tr key={val.id}>
                        <Td>{val.id}</Td>
                        <Td>{englishName}</Td>
                        <Td>
                          <Button
                            size="sm"
                            colorScheme="teal"
                            mr={2}
                            onClick={() => handleEditValue(val)}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            size="sm"
                            colorScheme="red"
                            onClick={() => handleDeleteValueConfirm(val)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
            {/* Form for Adding/Editing an Attribute Value */}
            <Box mt="6">
              <Heading size="md" mb="2">
                {editingValue ? 'Edit Value' : 'Add New Value'}
              </Heading>
              <FormControl mb="4">
                <FormLabel>Slug</FormLabel>
                <Input
                  placeholder="Enter value slug"
                  value={valueFormData.slug}
                  onChange={(e) =>
                    setValueFormData({
                      ...valueFormData,
                      slug: e.target.value,
                    })
                  }
                />
              </FormControl>
              <Tabs variant="enclosed" colorScheme="blue" mb="20px">
                <TabList>
                  {languages.map((lang) => (
                    <Tab key={lang.code}>{lang.name}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {languages.map((lang) => (
                    <TabPanel key={lang.code}>
                      <FormControl>
                        <FormLabel>Name ({lang.name})</FormLabel>
                        <Input
                          placeholder={`Enter value name in ${lang.name}`}
                          value={valueFormData.translations[lang.code] || ''}
                          onChange={(e) =>
                            setValueFormData({
                              ...valueFormData,
                              translations: {
                                ...valueFormData.translations,
                                [lang.code]: e.target.value,
                              },
                            })
                          }
                        />
                      </FormControl>
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
              <Button colorScheme="blue" onClick={handleValueSubmit}>
                {editingValue ? 'Update Value' : 'Create Value'}
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={closeValuesModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation AlertDialog for Attribute Deletion */}
      <AlertDialog
        isOpen={isAttrDeleteConfirmOpen}
        leastDestructiveRef={attrCancelRef}
        onClose={onAttrDeleteConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Attribute
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this attribute? This action cannot
              be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={attrCancelRef} onClick={onAttrDeleteConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteAttribute} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Confirmation AlertDialog for Attribute Value Deletion */}
      <AlertDialog
        isOpen={isValueDeleteConfirmOpen}
        leastDestructiveRef={valueCancelRef}
        onClose={onValueDeleteConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Attribute Value
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this attribute value? This action
              cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={valueCancelRef} onClick={onValueDeleteConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteValue} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default AttributesPage;
