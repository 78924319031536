//DiscountFilters.js
import React, { useEffect } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Select,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  useColorModeValue,
  VStack,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

const DiscountFilters = ({
  isOpen,
  onClose,
  productUUID,
  setProductUUID,
  loyaltyProgramUUID,
  setLoyaltyProgramUUID,
  loyaltyLevelUUID,
  setLoyaltyLevelUUID,
  isActive,
  setIsActive,
  resetFilters,
  filteredProducts,
  filteredLoyaltyPrograms,
  filteredLoyaltyLevels,
  setPagination,
  onApply,
}) => {
  useEffect(() => {
    setLoyaltyLevelUUID('');
  }, [loyaltyProgramUUID, setLoyaltyLevelUUID]);

  const bgColor = useColorModeValue('white', 'gray.800');

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent bg={bgColor}>
        <DrawerHeader borderBottomWidth="1px">Filter Discounts</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch">
            {/* Product Filter */}
            <FormControl>
              <FormLabel>Product</FormLabel>
              <Select
                value={productUUID}
                onChange={(e) => {
                  setProductUUID(e.target.value);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
                placeholder="All Products"
              >
                {filteredProducts && filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <option key={product.uuid} value={product.uuid}>
                      {product.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No Products Available</option>
                )}
              </Select>
            </FormControl>

            {/* Loyalty Program Filter */}
            <FormControl>
              <FormLabel>Loyalty Program</FormLabel>
              <Select
                value={loyaltyProgramUUID}
                onChange={(e) => {
                  setLoyaltyProgramUUID(e.target.value);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
                placeholder="All Loyalty Programs"
              >
                {filteredLoyaltyPrograms &&
                filteredLoyaltyPrograms.length > 0 ? (
                  filteredLoyaltyPrograms.map((program) => (
                    <option key={program.uuid} value={program.uuid}>
                      {program.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No Loyalty Programs Available</option>
                )}
              </Select>
            </FormControl>

            {/* Loyalty Level Filter */}
            <FormControl>
              <FormLabel>Loyalty Level</FormLabel>
              <Select
                value={loyaltyLevelUUID}
                onChange={(e) => {
                  setLoyaltyLevelUUID(e.target.value);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
                placeholder="All Loyalty Levels"
                isDisabled={!loyaltyProgramUUID}
              >
                {filteredLoyaltyLevels && filteredLoyaltyLevels.length > 0 ? (
                  filteredLoyaltyLevels.map((level) => (
                    <option key={level.uuid} value={level.uuid}>
                      {level.name}
                    </option>
                  ))
                ) : (
                  <option disabled>
                    {loyaltyProgramUUID
                      ? 'No Loyalty Levels for Selected Program'
                      : 'Select a Loyalty Program first'}
                  </option>
                )}
              </Select>
            </FormControl>

            {/* Active Status Filter */}
            <FormControl display="flex" alignItems="center">
              <Checkbox
                isChecked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                }}
              >
                Active
              </Checkbox>
            </FormControl>
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack spacing={3}>
            <IconButton
              aria-label="Reset Filters"
              icon={<RepeatIcon />}
              onClick={resetFilters}
            />
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={onApply || onClose}>
              Apply Filters
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DiscountFilters;
