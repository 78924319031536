// components/products/ProductImagesCard.jsx
import React from 'react';
import { Box, IconButton, Image, Text } from '@chakra-ui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  StarIcon,
} from '@chakra-ui/icons';
import Card from '../../components/card/Card';
import MagnifiableImage from '../../components/products/MagnifiableImage';

function ProductImagesCard({
  images,
  currentImageIndex,
  onPrev,
  onNext,
  onDelete,
  onSetFeatured,
  combinedImages,
}) {
  return (
    <Card position="relative" mb="20px">
      <Box
        position="relative"
        width="100%"
        height="400px"
        maxWidth="600px"
        mx="auto"
      >
        <IconButton
          icon={<ChevronLeftIcon />}
          position="absolute"
          left="10px"
          top="50%"
          transform="translateY(-50%)"
          zIndex="10"
          variant="ghost"
          onClick={onPrev}
          aria-label="Previous Image"
        />
        <MagnifiableImage
          borderRadius="20px"
          width="100%"
          height="100%"
          objectFit="cover"
          src={
            combinedImages.length > 0
              ? combinedImages[currentImageIndex].image_url
              : 'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg'
          }
          alt="Product Image"
          onNext={onNext}
          onPrev={onPrev}
        />
        <IconButton
          icon={<ChevronRightIcon />}
          position="absolute"
          right="10px"
          top="50%"
          transform="translateY(-50%)"
          zIndex="10"
          variant="ghost"
          onClick={onNext}
          aria-label="Next Image"
        />
      </Box>
      <Box p="20px">
        <Text fontSize="xl" fontWeight="bold" mb="10px">
          Images Gallery
        </Text>
        {combinedImages.length === 0 ? (
          <Text>No images available.</Text>
        ) : (
          <Box display="flex" flexWrap="nowrap" gap={4} overflowX="auto" py={2}>
            {combinedImages.map((image) => (
              <Box key={image.uuid} position="relative" flex="0 0 auto">
                <Image
                  src={image.image_url}
                  alt="Product"
                  borderRadius="md"
                  objectFit="cover"
                  boxSize="100px"
                />
                {/* Only show actions for existing images */}
                {onDelete && onSetFeatured && (
                  <>
                    <IconButton
                      icon={<DeleteIcon />}
                      position="absolute"
                      top={2}
                      right={2}
                      size="sm"
                      onClick={() => onDelete(image.uuid)}
                      aria-label="Delete image"
                    />
                    <IconButton
                      icon={
                        <StarIcon
                          color={image.is_featured ? 'yellow.500' : 'gray.300'}
                        />
                      }
                      position="absolute"
                      bottom={2}
                      right={2}
                      size="sm"
                      onClick={() => onSetFeatured(image.uuid)}
                      aria-label="Set featured image"
                    />
                  </>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default ProductImagesCard;
