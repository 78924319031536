// UserDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import { Grid, Text, Box, Image, Flex } from '@chakra-ui/react';
import { getUserDetails } from 'api/user';
import { format, addDays, isAfter } from 'date-fns';
import { Tag } from '@chakra-ui/react';

// Import custom components
import Banner from '../../components/users/Banner';

// Assets
import banner from 'assets/img/auth/banner.png';
// Remove the static avatar import
// import avatar from 'assets/img/avatars/avatar4.png';
import NftProfile from 'assets/img/nfts/NftProfile.png';

export default function UserDetail() {
  const { userUuid } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAllLoyalty, setShowAllLoyalty] = useState(false);
  const [showAllOther, setShowAllOther] = useState(false);

  const itemsToShow = 3;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getUserDetails(userUuid);
        setUserData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false); // Ensure loading state is updated even on error
      }
    };

    fetchUserData();
  }, [userUuid]);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (!userData) {
    return <Text>No user data found</Text>;
  }

  const sortByDate = (a, b) => {
    return new Date(b.purchase_date) - new Date(a.purchase_date);
  };

  const checkExpiration = (purchase) => {
    if (!purchase.expiration_period) return false;
    const expirationDate = addDays(
      new Date(purchase.purchase_date),
      purchase.expiration_period,
    );
    return isAfter(new Date(), expirationDate);
  };

  const sortByDateAndExpiration = (a, b) => {
    const aExpired = checkExpiration(a);
    const bExpired = checkExpiration(b);

    if (aExpired && !bExpired) return 1;
    if (!aExpired && bExpired) return -1;

    return new Date(b.purchase_date) - new Date(a.purchase_date);
  };

  const loyaltyPrograms = userData.loyalty
    .filter((purchase) => purchase.loyalty_program_uuid)
    .sort(sortByDateAndExpiration);

  const regularPrograms = userData.purchases
    .filter((purchase) => !purchase.loyalty_program_uuid)
    .sort(sortByDate);

  const displayedLoyaltyPrograms = showAllLoyalty
    ? loyaltyPrograms
    : loyaltyPrograms.slice(0, itemsToShow);

  const displayedRegularPrograms = showAllOther
    ? regularPrograms
    : regularPrograms.slice(0, itemsToShow);

  const productSpent =
    userData.purchases.reduce(
      (total, purchase) => total + purchase.purchase_price,
      0,
    ) || 0;

  const loyaltySpent =
    userData.loyalty.reduce(
      (total, purchase) => total + purchase.purchase_price,
      0,
    ) || 0;

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1fr 1fr 1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        <Card h="500px">
          <Banner
            banner={banner}
            avatar={userData.avatar_url}
            name={userData.full_name}
            email={userData.email}
            lastlogin={userData.last_login}
            purchases={userData.purchases.length + userData.loyalty.length}
            moneySpent={
              Math.round((productSpent + Number.EPSILON) * 100) / 100 +
              Math.round((loyaltySpent + Number.EPSILON) * 100) / 100
            }
          />
        </Card>
        <Card p="4" display="flex" flexDirection="column" minH="100%">
          <Text fontSize="xl" fontWeight="bold" mb="4">
            Loyalty Purchases
          </Text>
          {displayedLoyaltyPrograms.length ? (
            displayedLoyaltyPrograms.map((purchase) => (
              <Box
                key={purchase.uuid}
                onClick={() => navigate(`/admin/orders/${purchase.uuid}`)}
                cursor="pointer"
                p="4"
                bg="white"
                boxShadow="sm"
                borderRadius="xl"
                mb="4"
                display="flex"
                alignItems="center"
                borderWidth="1px"
                borderColor="gray.100"
                _hover={{
                  boxShadow: 'md',
                  transform: 'translateY(-2px)',
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <Image
                  boxSize="50px"
                  src={purchase.images?.[0]?.image_url || NftProfile}
                  alt={purchase.product_name}
                  mr="4"
                />
                <Box flex="1">
                  <Flex justify="space-between" align="center">
                    <Text fontWeight="bold">{purchase.product_name}</Text>
                    {checkExpiration(purchase) && (
                      <Tag colorScheme="red" size="sm">
                        Expired
                      </Tag>
                    )}
                  </Flex>
                  <Text>
                    Purchase Date:{' '}
                    {format(new Date(purchase.purchase_date), 'dd.MM.yyyy')}
                  </Text>
                  <Text>Price: {purchase.purchase_price}€</Text>
                </Box>
              </Box>
            ))
          ) : (
            <Text>No loyalty purchases yet</Text>
          )}
          {loyaltyPrograms.length > itemsToShow && (
            <Text
              color="blue.500"
              cursor="pointer"
              onClick={() => setShowAllLoyalty(!showAllLoyalty)}
              mt="auto"
              pt="4"
            >
              {showAllLoyalty
                ? 'Show Less'
                : `Show More (${loyaltyPrograms.length - itemsToShow} more)`}
            </Text>
          )}
        </Card>

        <Card p="4" display="flex" flexDirection="column" minH="100%">
          <Text fontSize="xl" fontWeight="bold" mb="4">
            Regular Purchases
          </Text>
          {displayedRegularPrograms.length ? (
            displayedRegularPrograms.map((purchase) => (
              <Box
                key={purchase.uuid}
                onClick={() => navigate(`/admin/orders/${purchase.uuid}`)}
                cursor="pointer"
                p="4"
                bg="white"
                boxShadow="sm"
                borderRadius="xl"
                mb="4"
                display="flex"
                alignItems="center"
                borderWidth="1px"
                borderColor="gray.100"
                _hover={{
                  boxShadow: 'md',
                  transform: 'translateY(-2px)',
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <Image
                  boxSize="50px"
                  src={purchase.images?.[0]?.image_url || NftProfile}
                  alt={purchase.product_name}
                  mr="4"
                />
                <Box>
                  <Text fontWeight="bold">{purchase.product_name}</Text>
                  <Text>
                    Purchase Date:{' '}
                    {format(new Date(purchase.purchase_date), 'dd.MM.yyyy')}
                  </Text>
                  <Text>Price: {purchase.purchase_price}€</Text>
                </Box>
              </Box>
            ))
          ) : (
            <Text>No purchases yet</Text>
          )}
          {regularPrograms.length > itemsToShow && (
            <Text
              color="blue.500"
              cursor="pointer"
              onClick={() => setShowAllOther(!showAllOther)}
              mt="auto"
              pt="4"
            >
              {showAllOther
                ? 'Show Less'
                : `Show More (${regularPrograms.length - itemsToShow} more)`}
            </Text>
          )}
        </Card>
      </Grid>
    </Box>
  );
}
