import { NavLink, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Text,
  List,
  Icon,
  ListItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

export function SidebarLinks(props) {
  let location = useLocation();
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue(
    'secondaryGray.600',
    'secondaryGray.600',
  );
  let activeIcon = useColorModeValue('brand.500', 'white');

  const { routes, hovered, mini } = props;

  // Updated activeRoute function with a guard for undefined
  const activeRoute = (routeName) => {
    if (!routeName) return false;
    return location.pathname.includes(routeName.toLowerCase());
  };

  const createLinks = (routes) => {
    return routes
      .filter((route) => !route.hidden)
      .map((route, key) => {
        if (route.collapse) {
          return (
            <Accordion
              defaultIndex={
                activeRoute(route.path ? route.path.toLowerCase() : '')
                  ? 0
                  : 'unset'
              }
              allowToggle
              key={key}
            >
              <AccordionItem maxW="100%" border="none" key={key}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{ bg: 'unset' }}
                  _focus={{ boxShadow: 'none' }}
                  borderRadius="8px"
                  w={{ sm: '92%', xl: '92%', '2xl': '92%' }}
                  px={route.icon ? null : '0px'}
                  py="0px"
                  bg={'transparent'}
                  ms={0}
                >
                  {route.icon ? (
                    <Flex
                      align="center"
                      justifyContent={
                        mini === false
                          ? 'space-between'
                          : mini === true && hovered === true
                            ? 'space-between'
                            : 'center'
                      }
                      w="100%"
                    >
                      <HStack
                        mb="6px"
                        spacing={
                          activeRoute(
                            route.path ? route.path.toLowerCase() : '',
                          )
                            ? '22px'
                            : '26px'
                        }
                      >
                        <Flex
                          w="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box
                            color={
                              activeRoute(
                                route.path ? route.path.toLowerCase() : '',
                              )
                                ? activeIcon
                                : inactiveColor
                            }
                            me={
                              mini === false
                                ? '12px'
                                : mini === true && hovered === true
                                  ? '12px'
                                  : '0px'
                            }
                            mt="6px"
                          >
                            {route.icon}
                          </Box>
                          <Text
                            display={
                              mini === false
                                ? 'block'
                                : mini === true && hovered === true
                                  ? 'block'
                                  : 'none'
                            }
                            me="auto"
                            color={
                              activeRoute(
                                route.path ? route.path.toLowerCase() : '',
                              )
                                ? activeColor
                                : 'secondaryGray.600'
                            }
                            fontWeight="500"
                            fontSize="md"
                          >
                            {route.name}
                          </Text>
                        </Flex>
                      </HStack>
                      <AccordionIcon
                        display={
                          mini === false
                            ? 'block'
                            : mini === true && hovered === true
                              ? 'block'
                              : 'none'
                        }
                        ms="auto"
                        color={'secondaryGray.600'}
                        transform={route.icon ? null : 'translateX(-70%)'}
                      />
                    </Flex>
                  ) : (
                    <Flex
                      pt="0px"
                      pb="10px"
                      justify={'center'}
                      alignItems="center"
                      w="100%"
                    >
                      <HStack
                        spacing={
                          activeRoute(
                            route.path ? route.path.toLowerCase() : '',
                          )
                            ? '22px'
                            : '26px'
                        }
                        ps={
                          mini === false
                            ? '34px'
                            : mini === true && hovered === true
                              ? '34px'
                              : '0px'
                        }
                      >
                        <Text
                          me="auto"
                          color={
                            activeRoute(
                              route.path ? route.path.toLowerCase() : '',
                            )
                              ? activeColor
                              : inactiveColor
                          }
                          fontWeight="500"
                          fontSize="sm"
                        >
                          {mini === false
                            ? route.name
                            : mini === true && hovered === true
                              ? route.name
                              : route.name[0]}
                        </Text>
                      </HStack>
                      <AccordionIcon
                        display={
                          mini === false
                            ? 'block'
                            : mini === true && hovered === true
                              ? 'block'
                              : 'none'
                        }
                        ms="auto"
                        color={'secondaryGray.600'}
                        transform={null}
                      />
                    </Flex>
                  )}
                </AccordionButton>
                <AccordionPanel
                  display={
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                        ? 'block'
                        : 'flex'
                  }
                  justifyContent="center"
                  alignItems="center"
                  flexDirection={'column'}
                  pe={route.icon ? '14px !important' : '0px'}
                  py="0px"
                  ps={route.icon ? '14px !important' : '8px'}
                >
                  <List>
                    {route.icon
                      ? createLinks(route.items)
                      : createAccordionLinks(route.items)}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        } else {
          return (
            <NavLink to={(route.layout || '') + (route.path || '')} key={key}>
              {route.icon ? (
                <Flex
                  align="center"
                  justifyContent="space-between"
                  wrap="wrap"
                  w="100%"
                  ps="17px"
                  mb="0px"
                >
                  <HStack
                    mb="6px"
                    spacing={
                      activeRoute(route.path ? route.path.toLowerCase() : '')
                        ? '22px'
                        : '26px'
                    }
                  >
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box
                        color={
                          activeRoute(
                            route.path ? route.path.toLowerCase() : '',
                          )
                            ? activeIcon
                            : inactiveColor
                        }
                        me="12px"
                        mt="6px"
                      >
                        {route.icon}
                      </Box>
                      <Text
                        me="auto"
                        color={
                          activeRoute(
                            route.path ? route.path.toLowerCase() : '',
                          )
                            ? activeColor
                            : 'secondaryGray.600'
                        }
                        fontWeight="500"
                      >
                        {mini === false
                          ? route.name
                          : mini === true && hovered === true
                            ? route.name
                            : route.name[0]}
                      </Text>
                    </Flex>
                  </HStack>
                </Flex>
              ) : (
                <List>
                  <ListItem ms={null}>
                    <Flex
                      ps={
                        mini === false
                          ? '34px'
                          : mini === true && hovered === true
                            ? '34px'
                            : '0px'
                      }
                      alignItems="center"
                      mb="8px"
                    >
                      <Text
                        color={
                          activeRoute(
                            route.path ? route.path.toLowerCase() : '',
                          )
                            ? activeColor
                            : inactiveColor
                        }
                        fontWeight="500"
                        fontSize="sm"
                      >
                        {mini === false
                          ? route.name
                          : mini === true && hovered === true
                            ? route.name
                            : route.name[0]}
                      </Text>
                    </Flex>
                  </ListItem>
                </List>
              )}
            </NavLink>
          );
        }
      });
  };

  const createAccordionLinks = (routes) => {
    return routes.map((route, key) => (
      <NavLink to={(route.layout || '') + (route.path || '')} key={key}>
        <List>
          <ListItem
            ms={
              mini === false
                ? '28px'
                : mini === true && hovered === true
                  ? '28px'
                  : '0px'
            }
            display="flex"
            alignItems="center"
            mb="10px"
          >
            <Icon w="6px" h="6px" me="8px" as={FaCircle} color={activeIcon} />
            <Text
              color={
                activeRoute(route.path ? route.path.toLowerCase() : '')
                  ? activeColor
                  : inactiveColor
              }
              fontWeight={
                activeRoute(route.path ? route.path.toLowerCase() : '')
                  ? 'bold'
                  : 'normal'
              }
              fontSize="sm"
            >
              {mini === false
                ? route.name
                : mini === true && hovered === true
                  ? route.name
                  : route.name[0]}
            </Text>
          </ListItem>
        </List>
      </NavLink>
    ));
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
