import React, { useState, useEffect, useCallback } from 'react';
import {
  Flex,
  Text,
  useToast,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCloudUpload } from 'react-icons/md';

import ProductForm from '../../components/products/ProductForm';
import ProductImagesCard from '../../components/products/ProductImagesCard';
import Dropzone from '../../components/products/Dropzone';
import Card from '../../components/card/Card';
import ProductVariationsSection from '../../components/products/ProductVariationsSection';
import ProductAttributesSection from '../../components/products/ProductAttributesSection';
import CategoryAttributesSection from '../../components/products/CategoryAttributesSection';

import {
  createProduct,
  updateProduct,
  handleImageUpload,
  getProducts,
  getProductDetails,
} from '../../api/product';
import { getCategories } from '../../api/category';
import { setTranslation } from '../../utils/translationUtils';
import {
  transformCategoriesForTreeSelect,
  addProductCounts,
} from '../../utils/CategoryUtils';
import {
  getAttributes,
  getAttributeValues,
  getProductAttributes,
} from '../../api/attributesVariations';

export default function NewProduct() {
  const navigate = useNavigate();
  const toast = useToast();

  // Basic product state
  const [productId, setProductId] = useState(null);
  const [isFeatured, setIsFeatured] = useState(false);
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [productPrice, setProductPrice] = useState('');
  const [visibility, setVisibility] = useState('public');

  const [languages] = useState([
    { code: 'en', name: 'English' },
    { code: 'et', name: 'Estonian' },
  ]);
  const [translations, setTranslations] = useState({
    en: { name: '', description: '' },
    et: { name: '', description: '' },
  });
  const [productType, setProductType] = useState('physical');
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState('');
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const [additionalFields, setAdditionalFields] = useState({
    sku: '',
    isbn: '',
    ean_upc: '',
    external_id: '',
    weight: '',
    weight_unit: '',
    external_url: '',
    tax_class: '',
    country_of_origin: '',
    brand_name: '',
    dimensions: '',
  });

  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [linkedAttributes, setLinkedAttributes] = useState([]);
  const filteredAttributes = availableAttributes.filter((attr) =>
    linkedAttributes.some((linkedAttr) => linkedAttr.id === attr.id),
  );

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoadingCategories(true);
      try {
        const data = await getCategories();
        const productsResponse = await getProducts();
        const counts = {};
        productsResponse.forEach((product) => {
          if (product.category_uuid) {
            counts[product.category_uuid] =
              (counts[product.category_uuid] || 0) + 1;
          }
        });
        let treeCategories = transformCategoriesForTreeSelect(data, 'en');
        treeCategories = addProductCounts(treeCategories, counts);
        setCategoriesTree(treeCategories);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        toast({
          title: 'Error fetching categories.',
          description: 'Unable to load categories. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingCategories(false);
      }
    };

    fetchCategories();
  }, [toast]);

  useEffect(() => {
    const fetchAttributesAndValues = async () => {
      try {
        const [attrs, attrValues] = await Promise.all([
          getAttributes(),
          getAttributeValues(),
        ]);
        const combined = attrs.map((attr) => ({
          ...attr,
          values: attrValues.filter((val) => val.attribute_id === attr.id),
        }));
        setAvailableAttributes(combined);
      } catch (error) {
        console.error('Error fetching attributes and values:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch attributes and values.',
          status: 'error',
          isClosable: true,
        });
      }
    };
    fetchAttributesAndValues();
  }, [toast]);

  const refreshLinkedAttributes = useCallback(async () => {
    try {
      if (!productId) return;
      const data = await getProductAttributes(productId);
      setLinkedAttributes(data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // No attributes have been added yet – treat this as an empty array.
        setLinkedAttributes([]);
      }
    }
  }, [productId, toast]);

  // Delay fetching linked attributes by 500ms once productId is set.
  useEffect(() => {
    if (productId) {
      const timer = setTimeout(() => {
        refreshLinkedAttributes();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [productId, refreshLinkedAttributes]);

  // Optional: Verify that the product exists in the backend before fetching linked data.
  const verifyProductExists = async (id, maxAttempts = 5, delay = 300) => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        const product = await getProductDetails(id);
        if (product) {
          return true;
        }
      } catch (error) {
        // Optionally log the error.
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    return false;
  };

  const handleTranslationChange = (lang, field, value) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], [field]: value },
    }));
  };

  const handlePrevImage = () => {
    if (images.length === 0) return;
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const handleNextImage = () => {
    if (images.length === 0) return;
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handleImageDrop = (acceptedFiles) => {
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024;
    const newValidFiles = [];

    acceptedFiles.forEach((file) => {
      if (!validTypes.includes(file.type)) {
        toast({
          title: 'Invalid file type.',
          description: 'Only PNG, JPG, and GIF files are allowed.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else if (file.size > maxSize) {
        toast({
          title: 'File too large.',
          description: 'Image size should be less than 5MB.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        newValidFiles.push(file);
      }
    });

    if (newValidFiles.length > 0) {
      setImages((prev) => {
        const newImages = [...prev, ...newValidFiles];
        setCurrentImageIndex(newImages.length - 1);
        return newImages;
      });
    }
  };

  const handleDeleteImageWrapper = (imageUuid) => {
    setImages((prevImages) =>
      prevImages.filter(
        (img) => `${img.name}-${img.lastModified}` !== imageUuid,
      ),
    );
    setCurrentImageIndex(0);
    toast({
      title: 'Image Removed',
      description: 'The image has been removed from selection.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSetFeaturedImageWrapper = (imageUuid) => {
    setImages((prevImages) => {
      const index = prevImages.findIndex(
        (img) => `${img.name}-${img.lastModified}` === imageUuid,
      );
      if (index === -1) return prevImages;
      const newImages = [...prevImages];
      const [selectedImage] = newImages.splice(index, 1);
      newImages.unshift(selectedImage);
      return newImages;
    });
    setIsFeatured(true);
    setCurrentImageIndex(0);
    toast({
      title: 'Featured Image Set',
      description: 'The image has been set as the featured image.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  // Unified save function: create a new product if no productId exists, or update if it does.
  const handleSaveProduct = async () => {
    const translationsPayload = {};
    languages.forEach((lang) => {
      translationsPayload[lang.code] = {
        product_name: translations[lang.code].name,
        product_description: translations[lang.code].description,
      };
    });

    const data = {
      price: Number(productPrice),
      is_featured: isFeatured,
      status: 'active',
      is_archived: false,
      quantity: quantity,
      category_uuid: selectedCategoryUuid || null,
      product_type: productType,
      translations: translationsPayload,
      sku: additionalFields.sku,
      isbn: additionalFields.isbn,
      ean_upc: additionalFields.ean_upc,
      external_id: additionalFields.external_id,
      weight: additionalFields.weight,
      weight_unit: additionalFields.weight_unit,
      external_url: additionalFields.external_url,
      tax_class: additionalFields.tax_class,
      country_of_origin: additionalFields.country_of_origin,
      brand_name: additionalFields.brand_name,
      dimensions: additionalFields.dimensions,
    };

    try {
      let currentProductId = productId;
      if (!productId) {
        // Create a new product
        const response = await createProduct(data);
        if (!response.uuid) {
          throw new Error('Product UUID not returned from the server.');
        }
        currentProductId = response.uuid;
        setProductId(currentProductId);

        // Optionally verify the product exists before fetching linked data:
        const exists = await verifyProductExists(currentProductId);
        if (exists) {
          refreshLinkedAttributes();
        }
      } else {
        // Update the existing product
        await updateProduct(productId, data);

        // Optionally verify the product exists before fetching linked data:
        const exists = await verifyProductExists(productId);
        if (exists) {
          refreshLinkedAttributes();
        }
      }

      // Upload images if any were selected.
      if (images.length > 0) {
        for (const imgFile of images) {
          await handleImageUpload([imgFile], currentProductId);
        }
      }

      // Update translations for each language.
      for (const lang of languages.map((l) => l.code)) {
        if (translations[lang]) {
          if (translations[lang].name?.trim()) {
            await setTranslation(
              `product_name_${currentProductId}`,
              translations[lang].name,
              lang,
            );
          }
          if (translations[lang].description?.trim()) {
            await setTranslation(
              `product_description_${currentProductId}`,
              translations[lang].description,
              lang,
            );
          }
        }
      }

      toast({
        title: productId ? 'Product updated.' : 'Product created.',
        description: productId
          ? 'The product has been updated successfully.'
          : 'The product has been created successfully. You can now add attributes and variations.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Refresh linked attributes after saving.
      if (currentProductId) {
        refreshLinkedAttributes();
      }
    } catch (error) {
      console.error('Error saving product:', error);
      toast({
        title: 'Error',
        description:
          error.message || 'An error occurred while saving the product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleQuantityChange = (value) => {
    const newQuantity = parseInt(value, 10) || 0;
    if (newQuantity < 0) {
      toast({
        title: 'Invalid Quantity',
        description: 'Quantity cannot be negative!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setQuantity(0);
    } else {
      setQuantity(newQuantity);
    }
  };

  // Clean up any created object URLs on unmount.
  useEffect(() => {
    return () => {
      images.forEach((img) => {
        if (img && typeof img === 'object') {
          URL.revokeObjectURL(img.preview);
        }
      });
    };
  }, [images]);

  const combinedImages = images.map((file, index) => ({
    uuid: `${file.name}-${file.lastModified}`,
    image_url: URL.createObjectURL(file),
    is_featured: index === 0,
  }));

  return (
    <>
      <ProductForm
        languages={languages}
        translations={translations}
        handleTranslationChange={handleTranslationChange}
        isFeatured={isFeatured}
        setIsFeatured={setIsFeatured}
        quantity={quantity}
        soldQuantity={0}
        handleQuantityChange={handleQuantityChange}
        selectedCategory={selectedCategoryUuid}
        categoriesTree={categoriesTree}
        isLoadingCategories={isLoadingCategories}
        handleCategoryChange={setSelectedCategoryUuid}
        productType={productType}
        setProductType={setProductType}
        visibility={visibility}
        setVisibility={setVisibility}
        productPrice={productPrice}
        setProductPrice={setProductPrice}
        saveAction={handleSaveProduct}
        additionalFields={additionalFields}
        setAdditionalFields={setAdditionalFields}
        leftChildren={
          <ProductImagesCard
            images={images}
            combinedImages={combinedImages}
            currentImageIndex={currentImageIndex}
            onPrev={handlePrevImage}
            onNext={handleNextImage}
            onDelete={handleDeleteImageWrapper}
            onSetFeatured={handleSetFeaturedImageWrapper}
          />
        }
      >
        <Card p="30px" mb="20px">
          <Text fontSize="xl" fontWeight="bold" mb="10px">
            Product Images
          </Text>
          <Dropzone onDrop={handleImageDrop}>
            <Flex direction="column" align="center" justify="center" h="200px">
              <MdOutlineCloudUpload
                size="50px"
                style={{ marginBottom: '8px' }}
              />
              <Text fontSize="lg" fontWeight="bold">
                Drag and drop images here, or click to select files
              </Text>
              <Text fontSize="sm" color="gray.500">
                PNG, JPG, and GIF files are allowed
              </Text>
            </Flex>
          </Dropzone>
        </Card>
      </ProductForm>

      {/* Show additional sections only if a product has been created */}
      {productId ? (
        <>
          <Tabs variant="enclosed" colorScheme="blue" mt="4">
            <TabList>
              <Tab>Linked Product Attributes</Tab>
              <Tab>Linked Category Attributes</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ProductAttributesSection
                  productId={productId}
                  onLinkedAttributesChange={refreshLinkedAttributes}
                />
              </TabPanel>
              <TabPanel>
                {selectedCategoryUuid ? (
                  <CategoryAttributesSection
                    categoryId={selectedCategoryUuid}
                    onLinkedAttributesChange={() =>
                      toast({
                        title: 'Category attributes updated',
                        status: 'success',
                        isClosable: true,
                      })
                    }
                  />
                ) : (
                  <Box>No category selected.</Box>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
          <ProductVariationsSection
            productId={productId}
            availableAttributes={filteredAttributes}
          />
        </>
      ) : (
        <Box mt="4">
          Save basic product info to add attributes and variations.
        </Box>
      )}
    </>
  );
}
