import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Heading,
  Button,
  Flex,
  Spinner,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import {
  getCategoryAttributes,
  addCategoryAttribute,
  removeCategoryAttribute,
  getAttributes,
} from '../../api/attributesVariations';
import ManageAttributesModal from './ManageAttributesModal';
import LinkedAttributesTags from './LinkedAttributesTags';
import { Select } from 'chakra-react-select';

const CategoryAttributesSection = ({
  categoryId,
  onLinkedAttributesChange,
}) => {
  const toast = useToast();
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [allAttributes, setAllAttributes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const {
    isOpen: isRemoveConfirmOpen,
    onOpen: onRemoveConfirmOpen,
    onClose: onRemoveConfirmClose,
  } = useDisclosure();
  const [attributeToRemove, setAttributeToRemove] = useState(null);
  const cancelRef = useRef();

  const fetchCategoryAttributes = async () => {
    try {
      setLoading(true);
      const data = await getCategoryAttributes(categoryId);
      setCategoryAttributes(data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setCategoryAttributes([]);
      } else {
        toast({
          title: 'Error',
          description: 'Failed to fetch category attributes.',
          status: 'error',
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchAllAttributes = async () => {
    try {
      const data = await getAttributes();
      setAllAttributes(data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch available attributes.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (categoryId) {
      fetchCategoryAttributes();
      fetchAllAttributes();
    }
  }, [categoryId]);

  const linkedAttributeIds = categoryAttributes.map((attr) => attr.id);
  const availableAttributesToLink = allAttributes.filter(
    (attr) => !linkedAttributeIds.includes(attr.id),
  );
  const options = availableAttributesToLink.map((attr) => ({
    value: attr.id,
    label:
      attr.translations?.find((tr) => tr.language === 'en')?.value || attr.slug,
  }));

  const handleAddAttributes = async () => {
    if (!selectedAttributes || selectedAttributes.length === 0) {
      toast({
        title: 'No attributes selected',
        status: 'warning',
        isClosable: true,
      });
      return;
    }
    try {
      for (const attribute of selectedAttributes) {
        await addCategoryAttribute(categoryId, Number(attribute.value));
      }
      toast({
        title: 'Attributes added',
        status: 'success',
        isClosable: true,
      });
      fetchCategoryAttributes();
      if (onLinkedAttributesChange) {
        onLinkedAttributesChange();
      }
      setSelectedAttributes([]);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add attribute(s) to category.',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleRemoveAttribute = (attributeId) => {
    setAttributeToRemove(attributeId);
    onRemoveConfirmOpen();
  };

  const confirmRemoveAttribute = async () => {
    try {
      await removeCategoryAttribute(categoryId, Number(attributeToRemove));
      toast({
        title: 'Attribute removed',
        status: 'success',
        isClosable: true,
      });
      fetchCategoryAttributes();
      if (onLinkedAttributesChange) {
        onLinkedAttributesChange();
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to remove attribute from category.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onRemoveConfirmClose();
      setAttributeToRemove(null);
    }
  };

  return (
    <Box mt="8">
      <Heading size="md" mb="4">
        Linked Category Attributes
      </Heading>
      <Flex alignItems="center" gap="2" mb="4">
        <Box maxW="250px" flex="1">
          <Select
            placeholder="Select attributes to add"
            value={selectedAttributes}
            onChange={setSelectedAttributes}
            options={options}
            isMulti
            isClearable
            closeMenuOnSelect={false}
          />
        </Box>
        <Button colorScheme="blue" onClick={handleAddAttributes}>
          Add Attribute to Category
        </Button>
        <ManageAttributesModal />
      </Flex>

      {loading ? (
        <Spinner />
      ) : categoryAttributes.length === 0 ? (
        <Box mb="4">No attributes linked yet.</Box>
      ) : (
        <LinkedAttributesTags
          attributes={categoryAttributes}
          onRemove={handleRemoveAttribute}
        />
      )}

      {/* Confirmation AlertDialog for attribute removal */}
      <AlertDialog
        isOpen={isRemoveConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onRemoveConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Attribute
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to remove this attribute from the category?
              This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onRemoveConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmRemoveAttribute} ml={3}>
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CategoryAttributesSection;
