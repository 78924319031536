// OrdersOverview.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Badge,
  Card,
  Icon,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { MdEdit, MdArrowDownward, MdArrowUpward } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import debounce from 'lodash/debounce';

// Import your API call and components
import { getOrders } from '../../api/order';
import Pagination from '../../components/pagination';
import SearchInput from '../../components/orders/SearchInput';
import OrdersFilter from '../../components/orders/OrdersFilter';

export default function OrdersOverview() {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  // Filter states (initialized from URL parameters)
  const [orderType, setOrderType] = useState(
    searchParams.get('product_type') || 'all',
  );
  const [status, setStatus] = useState(searchParams.get('status') || 'all');
  const [dateRange, setDateRange] = useState({
    start: searchParams.get('start_date') || '',
    end: searchParams.get('end_date') || '',
  });
  // Search state
  const [searchText, setSearchText] = useState(
    searchParams.get('search') || '',
  );

  // Pagination and Sorting
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const [pagination, setPagination] = useState({
    pageIndex: currentPage - 1,
    pageSize: parseInt(searchParams.get('limit')) || 20,
    pageCount: 0,
    total: 0,
  });
  const [sorting, setSorting] = useState([{ id: 'purchase_date', desc: true }]);

  // Orders data state
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  // Helper to update URL parameters
  const updateUrlParams = useCallback(
    (newParams) => {
      const current = Object.fromEntries([...searchParams]);
      const updated = { ...current, ...newParams };

      // Remove keys with undefined or empty string values
      Object.keys(updated).forEach(
        (key) =>
          (updated[key] === undefined || updated[key] === '') &&
          delete updated[key],
      );

      setSearchParams(updated, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  // Debounce search updates so that API calls aren’t made on every keystroke.
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        updateUrlParams({ search: value, page: 1 });
      }, 500),
    [updateUrlParams],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Fetch orders from API using current filters, pagination, and sorting
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const currentSort = sorting[0] || { id: 'purchase_date', desc: true };

        const filters = {
          status: status !== 'all' ? status : undefined,
          start_date: dateRange.start || undefined,
          end_date: dateRange.end || undefined,
          global_filter: searchParams.get('search') || undefined,
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          sort_by: currentSort.id,
          sort_order: currentSort.desc ? 'desc' : 'asc',
          product_type: orderType !== 'all' ? orderType : undefined,
        };

        const response = await getOrders(filters);

        setOrders(
          response.orders.map((order) => ({
            id: order.id,
            uuid: order.uuid,
            user_email: order.user_email || order.user_primary_email || 'N/A',
            product_name: order.product_name,
            product_type: order.product_type,
            purchase_date: new Date(order.purchase_date).toISOString(),
            purchase_price: parseFloat(order.purchase_price),
            status: order.status?.toLowerCase() || 'unknown',
          })),
        );

        setPagination((prev) => ({
          ...prev,
          pageCount: response.pagination.pageCount,
          total: response.pagination.total,
        }));
      } catch (error) {
        console.error('Error fetching orders:', error);
        toast({
          title: 'Error fetching orders',
          description: 'Unable to load orders. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [
    orderType,
    status,
    dateRange,
    searchParams,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    toast,
  ]);

  // Table configuration
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('blue.500', 'blue.400');
  const columnHelper = createColumnHelper();

  const SortableHeader = ({ column, children, align = 'left' }) => {
    const justifyContent =
      align === 'right'
        ? 'flex-end'
        : align === 'center'
          ? 'center'
          : 'flex-start';
    return (
      <Flex
        w="100%"
        cursor="pointer"
        onClick={() => column.toggleSorting()}
        userSelect="none"
        align="center"
        justifyContent={justifyContent}
      >
        <Text w="100%" textAlign={align}>
          {children}
        </Text>
        <Box ml={1}>
          {column.getIsSorted() &&
            (column.getIsSorted() === 'desc' ? (
              <Icon as={MdArrowDownward} w={4} h={4} />
            ) : (
              <Icon as={MdArrowUpward} w={4} h={4} />
            ))}
        </Box>
      </Flex>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: ({ column }) => (
          <SortableHeader column={column}>ID</SortableHeader>
        ),
        cell: (info) => (
          <Text color={textColor} fontWeight="500" fontSize="sm">
            #{info.getValue()}
          </Text>
        ),
        size: 50,
        maxWidth: 50,
      }),
      columnHelper.accessor('user_email', {
        header: ({ column }) => (
          <SortableHeader column={column}>Customer</SortableHeader>
        ),
        cell: (info) => (
          <Text
            color={textColor}
            fontSize="sm"
            noOfLines={1}
            title={info.getValue()}
          >
            {info.getValue()}
          </Text>
        ),
        size: 200,
        maxWidth: 200,
      }),
      columnHelper.accessor('product_name', {
        header: ({ column }) => (
          <SortableHeader column={column}>
            <Text textAlign="center" w="100%">
              Product
            </Text>
          </SortableHeader>
        ),
        cell: (info) => (
          <Text
            color={textColor}
            fontSize="sm"
            noOfLines={1}
            title={info.getValue()}
            textAlign="center" // explicitly center the cell content
          >
            {info.getValue()}
          </Text>
        ),
        size: 200,
        maxWidth: 250,
      }),
      columnHelper.accessor('purchase_date', {
        header: ({ column }) => (
          <SortableHeader column={column} align="right">
            Purchase Date
          </SortableHeader>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" textAlign="right">
            {format(new Date(info.getValue()), 'MMM dd, yyyy')}
          </Text>
        ),
        size: 120,
        maxWidth: 120,
      }),

      columnHelper.accessor('purchase_price', {
        header: ({ column }) => (
          <SortableHeader column={column} align="right">
            Price
          </SortableHeader>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" textAlign="right">
            €{parseFloat(info.getValue()).toFixed(2)}
          </Text>
        ),
        size: 100,
        maxWidth: 100,
      }),

      columnHelper.accessor('status', {
        header: 'Status',
        cell: (info) => (
          <Flex justify="center">
            <Badge
              colorScheme={
                {
                  complete: 'green',
                  paid: 'purple',
                  processing: 'yellow',
                  initiated: 'cyan',
                  failed: 'red',
                }[info.getValue()] || 'gray'
              }
              fontSize="sm"
              px={2}
              py={1}
              borderRadius="full"
            >
              {info.getValue().charAt(0).toUpperCase() +
                info.getValue().slice(1)}
            </Badge>
          </Flex>
        ),
        size: 100,
        maxWidth: 100,
      }),
      columnHelper.accessor('uuid', {
        header: '',
        cell: (info) => (
          <Button
            variant="ghost"
            size="sm"
            color={brandColor}
            leftIcon={<Icon as={MdEdit} />}
            onClick={() => navigate(`/admin/orders/${info.getValue()}`)}
            _hover={{ bg: 'transparent' }}
          />
        ),
        size: 50,
        maxWidth: 50,
      }),
    ],
    [columnHelper, navigate, textColor, brandColor],
  );

  const table = useReactTable({
    data: orders,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    manualPagination: true,
    pageCount: pagination.pageCount,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // Handlers for the OrdersFilter drawer
  const handleClearFilters = () => {
    setOrderType('all');
    setStatus('all');
    setDateRange({ start: '', end: '' });
    setSearchText('');
    updateUrlParams({
      product_type: undefined,
      status: undefined,
      start_date: undefined,
      end_date: undefined,
      search: undefined,
      page: 1,
    });
  };

  const handleApplyFilters = () => {
    updateUrlParams({
      product_type: orderType !== 'all' ? orderType : undefined,
      status: status !== 'all' ? status : undefined,
      start_date: dateRange.start || undefined,
      end_date: dateRange.end || undefined,
      page: 1,
    });
    onClose();
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card direction="column" w="100%" px="0px">
        <Flex p={6} mb={2} justify="space-between" align="center">
          <Box
            display="flex"
            alignItems="center"
            width={{ base: '100%', md: '300px' }}
          >
            <SearchInput
              onSearch={(value) => {
                setSearchText(value);
                debouncedSearch(value);
              }}
              initialValue={searchText}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Button
              onClick={onOpen}
              colorScheme="blue"
              variant="outline"
              size="sm"
            >
              <Icon as={FiFilter} boxSize={4} aria-label="Filter Orders" />
            </Button>
          </Box>
        </Flex>
        {loading ? (
          <Flex justify="center" align="center" h="200px">
            <Spinner size="xl" />
          </Flex>
        ) : orders.length > 0 ? (
          <>
            <Box overflowX="auto" maxW="100%">
              <Table variant="simple" size="sm">
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        // Define alignment based on the column id.
                        const align = [
                          'purchase_date',
                          'purchase_price',
                        ].includes(header.column.id)
                          ? 'right'
                          : header.column.id === 'status'
                            ? 'center'
                            : header.column.id === 'product_name'
                              ? 'center'
                              : 'left';
                        return (
                          <Th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            cursor={
                              header.column.getCanSort() ? 'pointer' : 'default'
                            }
                            color="gray.400"
                            borderColor={borderColor}
                            textAlign={align} // Set header alignment here
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>

                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        // Define alignment based on the column id.
                        const align = [
                          'purchase_date',
                          'purchase_price',
                        ].includes(cell.column.id)
                          ? 'right'
                          : cell.column.id === 'status' ||
                              cell.column.id === 'product_name'
                            ? 'center'
                            : 'left';
                        return (
                          <Td
                            key={cell.id}
                            borderColor={borderColor}
                            textAlign={align}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Flex justify="space-between" align="center" mt={4} px={6} pb={6}>
              <Text fontSize="sm" color="gray.600">
                Showing {orders.length} of {pagination.total} orders
              </Text>
              <Pagination
                currentPage={pagination.pageIndex}
                pageCount={pagination.pageCount}
                onChange={(newPage) => {
                  setPagination((prev) => ({ ...prev, pageIndex: newPage }));
                  updateUrlParams({ page: newPage + 1 });
                }}
              />
            </Flex>
          </>
        ) : (
          <Text textAlign="center" mt={20}>
            No orders found.
          </Text>
        )}
      </Card>

      {/* OrdersFilter Drawer */}
      <OrdersFilter
        isOpen={isOpen}
        onClose={onClose}
        orderType={orderType}
        setOrderType={setOrderType}
        status={status}
        setStatus={setStatus}
        dateRange={dateRange}
        setDateRange={setDateRange}
        onClear={handleClearFilters}
        onApply={handleApplyFilters}
      />
    </Box>
  );
}
