import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Stack,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  getProductVariations,
  createProductVariation,
  updateProductVariation,
  deleteProductVariation,
} from '../../api/attributesVariations';

const ProductVariationsSection = ({ productId, availableAttributes }) => {
  const toast = useToast();
  const [variations, setVariations] = useState([]);
  const [loadingVariations, setLoadingVariations] = useState(false);
  const [editingVariation, setEditingVariation] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [variationForm, setVariationForm] = useState({
    attributeValues: {},
    priceOverride: '',
    stockQuantity: '',
    isActive: true,
  });

  const {
    isOpen: isVariationModalOpen,
    onOpen: onVariationModalOpen,
    onClose: onVariationModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: onDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
  } = useDisclosure();
  const [variationToDelete, setVariationToDelete] = useState(null);
  const cancelRef = useRef();

  useEffect(() => {
    if (availableAttributes && availableAttributes.length > 0) {
      const emptyAttrValues = {};
      availableAttributes.forEach((attr) => {
        emptyAttrValues[attr.id] = '';
      });
      setVariationForm((prev) => ({
        ...prev,
        attributeValues: emptyAttrValues,
      }));
    }
  }, [availableAttributes]);

  const fetchVariations = async () => {
    setLoadingVariations(true);
    try {
      const data = await getProductVariations({ product_id: productId });
      setVariations(data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setVariations([]);
      } else {
        toast({
          title: 'Error',
          description: 'Failed to fetch variations.',
          status: 'error',
          isClosable: true,
        });
      }
    } finally {
      setLoadingVariations(false);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchVariations();
    }
  }, [productId]);

  const resetVariationForm = () => {
    const emptyAttrValues = {};
    availableAttributes.forEach((attr) => {
      emptyAttrValues[attr.id] = '';
    });
    setVariationForm({
      attributeValues: emptyAttrValues,
      priceOverride: '',
      stockQuantity: '',
      isActive: true,
    });
    setEditingVariation(null);
  };

  const handleVariationSubmit = async (e) => {
    e.preventDefault();

    const missingAttributes = availableAttributes.filter(
      (attr) => !variationForm.attributeValues[attr.id],
    );
    if (missingAttributes.length > 0) {
      toast({
        title: 'Validation Error',
        description: 'Please select all required attributes.',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);

    const transformedAttributeValues = {};
    Object.entries(variationForm.attributeValues).forEach(
      ([attrId, valueId]) => {
        if (!valueId) return;
        const attribute = availableAttributes.find(
          (attr) => Number(attr.id) === Number(attrId),
        );
        if (attribute) {
          const attributeKey = (
            attribute.translations?.find((tr) => tr.language === 'en')?.value ||
            attribute.slug
          ).toLowerCase();
          const valueObj = attribute.values.find(
            (val) => Number(val.id) === Number(valueId),
          );
          const valueName =
            valueObj?.translations?.find((tr) => tr.language === 'en')?.value ||
            valueObj?.slug ||
            valueId;
          transformedAttributeValues[attributeKey] = valueName;
        }
      },
    );

    const payload = {
      product_id: productId,
      attribute_values_json: transformedAttributeValues,
      price_override: variationForm.priceOverride
        ? Number(variationForm.priceOverride)
        : null,
      stock_quantity: variationForm.stockQuantity
        ? Number(variationForm.stockQuantity)
        : null,
      is_active: variationForm.isActive,
    };

    try {
      if (editingVariation) {
        await updateProductVariation(editingVariation.id, payload);
        toast({
          title: 'Variation updated',
          status: 'success',
          isClosable: true,
        });
      } else {
        await createProductVariation(payload);
        toast({
          title: 'Variation added',
          status: 'success',
          isClosable: true,
        });
      }
      fetchVariations();
      resetVariationForm();
      onVariationModalClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to save variation.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditVariation = (variation) => {
    const formValues = {};
    availableAttributes.forEach((attr) => {
      const attributeKey = (
        attr.translations?.find((tr) => tr.language === 'en')?.value ||
        attr.slug
      ).toLowerCase();
      if (
        variation.attribute_values_json &&
        variation.attribute_values_json[attributeKey]
      ) {
        const valueObj = attr.values.find((val) => {
          const valName =
            val.translations?.find((tr) => tr.language === 'en')?.value ||
            val.slug;
          return valName === variation.attribute_values_json[attributeKey];
        });
        formValues[attr.id] = valueObj ? valueObj.id : '';
      } else {
        formValues[attr.id] = '';
      }
    });
    setEditingVariation(variation);
    setVariationForm({
      attributeValues: formValues,
      priceOverride: variation.price_override || '',
      stockQuantity: variation.stock_quantity || '',
      isActive: variation.is_active,
    });
    onVariationModalOpen();
  };

  const handleDeleteVariation = (variation) => {
    setVariationToDelete(variation);
    onDeleteConfirmOpen();
  };

  const confirmDeleteVariation = async () => {
    try {
      await deleteProductVariation(variationToDelete.id);
      toast({
        title: 'Variation deleted',
        status: 'success',
        isClosable: true,
      });
      fetchVariations();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete variation.',
        status: 'error',
        isClosable: true,
      });
    } finally {
      onDeleteConfirmClose();
      setVariationToDelete(null);
    }
  };

  const isFormValid = () => {
    return availableAttributes.every(
      (attr) => variationForm.attributeValues[attr.id],
    );
  };

  return (
    <Box mt="8">
      <Heading size="md" mb="4">
        Product Variations
      </Heading>
      <Button colorScheme="blue" onClick={onVariationModalOpen} mb="4">
        Add Variation
      </Button>

      {/* Accordion to display variations */}
      {loadingVariations ? (
        <Spinner />
      ) : variations.length === 0 ? (
        <Box>No variations found.</Box>
      ) : (
        <Accordion allowToggle>
          {variations.map((variation) => (
            <AccordionItem key={variation.id}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {variation.attribute_values_json &&
                      Object.entries(variation.attribute_values_json).map(
                        ([attrKey, valueName]) => (
                          <span key={attrKey} style={{ marginRight: '8px' }}>
                            {attrKey}: {valueName}
                          </span>
                        ),
                      )}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box mb={2}>
                  <strong>Price Override:</strong>{' '}
                  {variation.price_override || '-'}
                </Box>
                <Box mb={2}>
                  <strong>Stock Quantity:</strong>{' '}
                  {variation.stock_quantity || '-'}
                </Box>
                <Box mb={2}>
                  <strong>Active:</strong> {variation.is_active ? 'Yes' : 'No'}
                </Box>
                <Flex mt={2} gap="2">
                  <Button
                    size="sm"
                    colorScheme="teal"
                    onClick={() => handleEditVariation(variation)}
                  >
                    <EditIcon mr={1} />
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleDeleteVariation(variation)}
                  >
                    <DeleteIcon mr={1} />
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}

      {/* Variation Modal (Add/Edit) */}
      <Modal
        isOpen={isVariationModalOpen}
        onClose={() => {
          onVariationModalClose();
          resetVariationForm();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingVariation ? 'Edit Variation' : 'Add Variation'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {availableAttributes && availableAttributes.length > 0 ? (
                availableAttributes.map((attr) => (
                  <FormControl key={attr.id} isRequired>
                    <FormLabel>
                      {attr.translations?.find((tr) => tr.language === 'en')
                        ?.value || attr.slug}
                    </FormLabel>
                    <Select
                      placeholder={`Select ${
                        attr.translations?.find((tr) => tr.language === 'en')
                          ?.value || attr.slug
                      }`}
                      value={variationForm.attributeValues[attr.id] || ''}
                      onChange={(e) =>
                        setVariationForm((prev) => ({
                          ...prev,
                          attributeValues: {
                            ...prev.attributeValues,
                            [attr.id]: e.target.value,
                          },
                        }))
                      }
                    >
                      {attr.values &&
                        attr.values.map((val) => (
                          <option key={val.id} value={val.id}>
                            {val.translations?.find(
                              (tr) => tr.language === 'en',
                            )?.value || val.slug}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                ))
              ) : (
                <Box color="red.500">
                  No available attributes to select. Please add/link attributes
                  to the product first.
                </Box>
              )}

              <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
                <GridItem>
                  <FormControl>
                    <FormLabel>Price Override</FormLabel>
                    <Input
                      type="number"
                      placeholder="Enter price override (optional)"
                      value={variationForm.priceOverride}
                      onChange={(e) =>
                        setVariationForm((prev) => ({
                          ...prev,
                          priceOverride: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Stock Quantity</FormLabel>
                    <Input
                      type="number"
                      placeholder="Enter stock quantity (optional)"
                      value={variationForm.stockQuantity}
                      onChange={(e) =>
                        setVariationForm((prev) => ({
                          ...prev,
                          stockQuantity: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => {
                onVariationModalClose();
                resetVariationForm();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleVariationSubmit}
              isLoading={isSubmitting}
              disabled={!isFormValid() || isSubmitting}
            >
              {editingVariation ? 'Update Variation' : 'Add Variation'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation AlertDialog for deleting variation */}
      <AlertDialog
        isOpen={isDeleteConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteConfirmClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Variation
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this variation? This action cannot
              be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteVariation} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ProductVariationsSection;
