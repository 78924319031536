import React, {useEffect, useState} from 'react';
import { Box, SimpleGrid, Tabs, TabList, TabPanels, Tab, TabPanel, Table, Thead, Tbody, Tr, Th, Td, Button, useToast, Flex, Text } from '@chakra-ui/react';
import apiClient from 'api/axios';
import { Global, css } from '@emotion/react';
import { format } from 'date-fns';

const editorStyles = {
    '.editor-input': {
      minHeight: '200px',
      padding: '10px',
      outline: 'none',
      width: '100%',
      cursor: 'text',
      fontSize: '16px',
      lineHeight: '1.5',
      '& h1': { fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' },
      '& h2': { fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' },
      '& h3': { fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' },
      '& strong': { fontWeight: 'bold' },
      '& em': { fontStyle: 'italic' },
      '& u': { textDecoration: 'underline' },
      '& ul': { listStyle: 'disc', marginLeft: '20px' },
      '& ol': { listStyle: 'decimal', marginLeft: '20px' }
    },
    '.editor-header-one': { fontSize: '2em', fontWeight: 'bold', margin: '0.67em 0' },
    '.editor-header-two': { fontSize: '1.5em', fontWeight: 'bold', margin: '0.83em 0' },
    '.editor-header-three': { fontSize: '1.17em', fontWeight: 'bold', margin: '1em 0' }
  };

export default function CompanySubscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const toast = useToast();

  useEffect(() => {
    // Load Stripe Pricing Table script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Fetch subscriptions
  const fetchSubscriptions = async () => {
    try {
      const response = await apiClient.get('/subscriptions', {
        params: { year: new Date().getFullYear() }
      });
      setSubscriptions(response.data.subscriptions || []);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      toast({
        title: 'Error fetching subscriptions',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  // Handle customer portal access
  const handleManageSubscription = async () => {
    try {
      const response = await apiClient.post('/create-portal-session', {
        returnUrl: window.location.href
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error accessing customer portal:', error);
      toast({
        title: 'Error accessing subscription management',
        description: error.response?.data?.error || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Global styles={editorStyles} />
      <Box p={5} mt={{ sm: '150px', md: '125px', lg: '80px' }}>
        <SimpleGrid columns={{ sm: 1, xl: 1 }} spacing={5} mb={5}>
          <Tabs w="100%">
          <Flex justify="space-between" align="center">
            <TabList>
              <Tab>General</Tab>
              <Tab>Invoices</Tab>
            </TabList>
            <Button
              colorScheme="blackAlpha"
              bg="black"
              color="white"
              variant="solid"
              onClick={() => window.open("https://billing.stripe.com/p/login/test_9AQg0E62s1Hxayc8ww", "_blank")}
            >
             Stripe Billing
            </Button>
            </Flex>
            <TabPanels>
              <TabPanel>
              {subscriptions.length > 0 && (
                <Text mt={4} fontSize="lg" fontWeight="bold" textAlign="center">
                  Current subscription ends on: {subscriptions[0].end_date ? format(new Date(subscriptions[0].end_date), 'MM/dd/yyyy') : 'Ongoing'}
                </Text>
              )}
                {scriptLoaded && (
                   <div style={{
                    borderRadius: "16px",
                    overflow: "hidden",
                  }}>
                  <stripe-pricing-table
                    pricing-table-id="prctbl_1QPjjXLJ4wJXMuTJav6aed2C"
                    publishable-key="pk_test_51Pqs65LJ4wJXMuTJO5aSQknYIHeNS4swumr5NYiqhw33rnSun1rCjvHoGpafvzDgTSFAD30IC6r5uiesqNWxmchP00TPdWl15A"
                    client-reference-id=""
                  />
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Subscription ID</Th>
                      <Th>Status</Th>
                      <Th>Start Date</Th>
                      <Th>End Date</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {subscriptions.length > 0 ? (
                      subscriptions.map((sub) => (
                        <Tr key={sub.stripe_subscription_id}>
                          <Td>{sub.stripe_subscription_id}</Td>
                          <Td>{sub.status}</Td>
                          <Td>{format(new Date(sub.start_date), 'MM/dd/yyyy')}</Td>
                          <Td>
                            {sub.end_date
                              ? format(new Date(sub.end_date), 'MM/dd/yyyy')
                              : 'Ongoing'}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan={4} textAlign="center">
                          No subscriptions found for this year.
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </SimpleGrid>
      </Box>
    </>
  );
}